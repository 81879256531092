import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from 'axios'
import CommonVariables from '../CommonVariables'
import UpdateLead from '../UpdateLead'
import { AuthKeyCheck } from '../Helpers'
import Swal from "sweetalert2";
const TopBand = (props) => {
    const [checkcallbacks, setCheckCallbacks] = useState(0);
    const [checkNotifications, setCheckNotifications] = useState(0);
    const userdata = useSelector(state => state.data.entities)
    const [token, setToken] = useState(userdata.session_key);
    useEffect(() => {
        setToken(userdata.session_key)
    }, [userdata])
    const [show_profile_menu, setShow_profile_menu] = useState(0)
    const [qstring, setQstring] = useState("");
    const [tblData, setTblData] = useState([])
    const [lid, setLid] = useState(0)
    const [agents, setAgents] = useState([])
    ///Edit Popup Function
    const ShowEditPopup = (props) => {
        let curl = window.location.href;
        const curlarray = curl.split("/");
        //console.log(curlarray);
        if (props.lid > 0) {
            return <UpdateLead
                lid={props.lid}
                ResetLid={ResetLid}
                compo={`/${curlarray[(curlarray.length - 1)]}`}
                agents={agents}
                setCheckCallbacks={setCheckCallbacks}
            />;
        }
    }
    const ResetLid = () => {
        setQstring("");
        setLid(0);
        setCheckCallbacks(checkcallbacks + 1);
    }
    const ShowProfileMenu = (e) => {
        if (e) {
            setShow_profile_menu(0)
        } else {
            setShow_profile_menu(1)
        }
    }
    //lead list
    useEffect(() => {
        const fetchTableData = async () => {
            const fbody = new FormData();
            fbody.append("actiontype", "freshleads");
            fbody.append("subactiontype", "search");
            fbody.append("action", "lead_list");
            fbody.append("length", "10");
            fbody.append("search[value]", qstring)
            // setDisplay(true);
            // let temp=qstring;
            // temp=temp.replace(/\s+/g, '');
            // setQstring(temp);
            await axios({
                method: "POST",
                url: CommonVariables.ApiUrl,
                data: fbody,
                headers: { 'Authorization': `Bearer ${token}` }
            })
                .then(function (apiresponse) {
                    AuthKeyCheck(apiresponse.data.urefid)
                    setTblData(apiresponse.data.data)
                })
                .catch(function (apierror) {
                    console.log(apierror)
                });
        }
        if (qstring.length > 2) {
            fetchTableData()
        }
    }, [qstring])
    const [display_name, setDisplay_name] = useState(userdata.name);
    useEffect(() => {
        const fetchTableData = async () => {
            const fbody = new FormData();
            fbody.append("action", "check_notification");
            await axios({
                method: "POST",
                url: CommonVariables.ApiUrl,
                data: fbody,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            })
                .then(function (apiresponse) {
                    // console.log("LINE94",apiresponse.data.rescode);
                    // if (apiresponse.data.rescode === 2) {
                    //     console.log("LINE96",apiresponse.data.rescode);
                    // }
                    AuthKeyCheck(apiresponse.data.urefid)
                    if (apiresponse.data.rescode === 1) {
                        Swal.fire({
                            // position: "top-end",
                            title: "Call Back Reminder!",
                            // width: 600,
                            // padding: "3em",
                            color: "#716add",
                            showCancelButton: true,
                            focusConfirm: false,
                            confirmButtonText: `<i class="fa fa-thumbs-up"></i> Update Status`,
                            confirmButtonAriaLabel: "Thumbs up, great!",
                            cancelButtonText: `<img width="10" height="20" style="float: left" src="/snooze.png" />`,
                            cancelButtonAriaLabel: "Snooze",
                            showCloseButton: false,
                            showConfirmButton: true,
                            text: `${apiresponse.data.data.business_name} - ${apiresponse.data.data.callback_date} at ${apiresponse.data.data.callback_time} `,
                            imageUrl: "/notify.jpg",
                            imageWidth: 100,
                            imageHeight: 100,
                            imageAlt: "Call Notification",
                            background: "#fff",
                            backdrop: `
                  rgba(0,0,123,0.4)
                `,
                            // footer: '<a>click here to see call due.</a>'
                        }).then(function (response) {
                            // console.log("response",response);
                            // return
                            if (response.isConfirmed === false) {

                                const fbody = new FormData();
                                fbody.append("lead_id", apiresponse.data.data.id);
                                fbody.append("action", "lead_update");
                                fbody.append("actiontype", "snooze");
                                axios({
                                    method: "POST",
                                    url: CommonVariables.ApiUrl,
                                    data: fbody,
                                    headers: { 'Authorization': `Bearer ${token}` },
                                }).then((res) => {
                                    Swal.fire({
                                        title: "Done!",
                                        text: "After 10 min will Remind Again.",
                                        icon: "success",
                                        timer: 2000,
                                        button: false,
                                    });
                                    setCheckCallbacks(checkcallbacks + 1)
                                });

                            } else if (response.isConfirmed === true) {
                                setLid(apiresponse.data.data.id)
                            }
                            setCheckNotifications(0);
                        })
                            .catch(function (response) {
                            });
                    } else if (apiresponse.data.rescode === 2) {
                        setCheckNotifications(30000+checkcallbacks)
                    }
                })
                .catch(function (apierror) {
                    console.log(apierror);
                });
        };
        if (token) {
            fetchTableData();
        }
    }, [token, checkcallbacks]
    )
    useEffect(() => {
        // console.log("LINE170", "Checking for Notifications..."+checkNotifications);
        
        if (checkNotifications > 0) {
            const timer = setTimeout(() => {
                setCheckCallbacks((prevCallbacks) => prevCallbacks + 1);
            }, checkNotifications);
            // Cleanup the timer when the component unmounts or before re-running
            return () => clearTimeout(timer);
        }
    }, [checkNotifications]);
    return (
        <div>

            <div className="mobile-block">
                <div className="mobile-nav navbar">
                    <div className="userprofile" /*onClick="show_userprofile()"*/> <img src="/assets/img/userlogo.png" /></div>
                    <nav className="navbar navbar-expand-lg navbar-light bg-light pl-0 pr-0 pb-0 "> <a className="navbar-brand prevent-select" href="#">Dashboard</a>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        > <span className="navbar-toggler-icon"></span> </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item active"> <Link className="nav-link" to="/">Dashboard</Link> </li>
                                <li className="nav-item"> <Link className="nav-link" to="/freshleads">Fresh Leads</Link></li>
                                <li className="nav-item"> <Link className="nav-link" to="/deals">Deals</Link> </li>
                                <li className="nav-item"> <Link className="nav-link" to="/usedleads">Used Leads</Link> </li>
                                <li className="nav-item"> <Link className="nav-link" to="/leadswithagents">Leads with Agents</Link> </li>
                                <li className="nav-item"> <Link className="nav-link" to="/leadpot">Lead pot</Link></li>
                                <li className="nav-item"> <Link className="nav-link" to="/agentpipeline">Agent Pipeline</Link></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="mobile-none">
                <ShowEditPopup
                    lid={lid}
                    compo='/'

                />
                <nav className="navbar navbar-expand-lg navbar-light bg-light"> <a className="navbar-brand" href="#">{props.pname}</a>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item active"> <a className="nav-link" href="#"
                            > <span className="sr-only"></span></a>
                            </li>
                            <li className="nav-item"> <a className="nav-link" href="#"></a> </li>
                            <li className="nav-item"> <a className="nav-link disabled" href="#"></a> </li>
                        </ul>
                        <div className='search_top'>
                            <form className="form-inline">
                                <input
                                    className="form-control"
                                    type="search"
                                    placeholder="Search"
                                    aria-label="Search"
                                    id="search-form"
                                    value={qstring}
                                    onChange={(e) => setQstring(e.target.value)}
                                    style={{ whiteSpace: 'nowrap' }}
                                />
                                <div className={`search-list bg-color ${tblData.length > 0 && qstring ? "" : "d-none"}`}>
                                    {/* <div className="search-list"> */}
                                    {tblData.map((result) => {
                                        return (
                                            <div className="search-click" key={result.id}>
                                                <p className="data-click" onClick={(e) => setLid(result.id)}>{result.business_name}<br /><span>{result.refid}  {result.status_txt}</span></p><hr />
                                            </div>
                                        )
                                    })}
                                </div>
                            </form>
                        </div>
                        <div className="userprofile" onClick={(e) => ShowProfileMenu(show_profile_menu)}>
                            <h6 className='prevent-select'>{display_name}</h6>
                            <img src="/assets/img/userlogo.png" />
                        </div>
                    </div>
                </nav>
            </div>
            <div className={`${show_profile_menu === 1 ? "box_profile d-block" : "d-none"}`}>
                <ul>
                    {/*<li><i className="fa fa-user"></i> <a href="settings.html">User settings</a></li>*/}
                    <li className={`${userdata.user_type === "1" ? "" : "d-none"}`}><i className="fa fa-users"></i>
                        <Link to="/team">Team</Link>
                    </li>
                    <li className={`${userdata.user_type === "1" ? "" : "d-none"}`}><i className="fa fa-file"></i> <Link to="/freshleads">Import</Link></li>
                    <li><i className="fa fa-sign-out"></i> <Link to="/logout">LogOut</Link></li>
                </ul>
            </div>
        </div>
    )
}
export default TopBand;